body {padding-top: 0px;padding-bottom: 0px;background-color: #eee;}


.overview h4 i {padding-right:15px;}


#content-wrapper {padding-left:250px;width:100%;height:100%; background-color:#eee;}


.profile-view{padding-left: 20px;padding-right: 20px;}

.profile-view p.small {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}
.side-nav-title-header {
    position: relative;
    text-align: center;
    z-index: 1;
    color: #fff;
    margin:14px 4px 0 4px;
}
.side-nav-title-header:before {
    border-top: 1px solid #9d9d9d;
    content:"";
    margin: 0 auto; /* this centers the line to the full width specified */
    position: absolute; /* positioning must be absolute here, and relative positioning must be applied to the parent */
    top: 50%; left: 0; right: 0; bottom: 0;
    width: 95%;
    z-index: -1;
}

.side-nav-title-header span { 
    /* to hide the lines from behind the text, you have to set the background color the same as the container */ 
    background: #222; 
    padding: 0 5px; 
    font-size:12px;
}

.title_col .pull-right .glyphicon {
    margin-left: 10px;
}

.animated, .show { visibility: visible !important; }
.wrapper {
    position:relative;
    margin:0 auto;
    overflow:hidden;
    padding:5px;
    height:50px;
}

.list {
    position:absolute;
    left:0px;
    top:0px;
    min-width:3000px;
    margin-left:12px;
    margin-top:0px;
}

.list li{
    display:table-cell;
    position:relative;
    text-align:center;
    cursor:grab;
    cursor:-webkit-grab;
    color:#efefef;
    vertical-align:middle;
}

.scroller {
    text-align:center;
    cursor:pointer;
    display:none;
    padding:7px;
    padding-top:11px;
    white-space:no-wrap;
    vertical-align:middle;
    background-color:#fff;
}

.scroller-right{
    float:right;
}

.scroller-left {
    float:left;
}
#scrollerNav {height:44px;}
#scrollerNav .nav-tabs>li>a:hover,#scrollerNav .nav-tabs>li.active>a, #scrollerNav .nav-tabs>li.active>a:focus, #scrollerNav .nav-tabs>li.active>a:hover {
    border:1px solid transparent;
    border-bottom:3px solid #337ab7;
    color:#337ab7;
    border-radius:0;
}
#scrollerNav .nav-tabs>li>a {
    border:1px solid transparent;
    border-bottom:3px solid transparent;
    color:#555;
    border-radius:0;
}

#scrollerNav .nav-tabs {
    border-bottom-color:#555;
}
#scrollerNav .nav-tabs>li>a {border-radius:0;}

.navbar-fixed-bottom div { padding-top: 7px;}
.panel-title i { font-size: 16px;padding:2px 12px 0 0;}
.panel-title {font-size:16px;}
.panel-title-large {font-size:23px;margin:0;}
.p-a-3 { padding:3px;}
.p-a-5 { padding:5px;}
.p-a-15 { padding:15px;}

.nopadding { padding:0px !important; }
.norightpadding { padding-right:0px !important; }
.notoppadding { padding-top:0px !important; }
.noleftpadding { padding-left:0px !important; }

#title_bar .head_title { 
    font-weight: bold;
    font-size: 16px;
}
#title_bar .title_col { padding-top: 5px; padding-bottom: 5px}
#title_bar .title_ellipsis { white-space: nowrap; overflow: hidden; text-overflow: ellipsis;}
#title_bar a { color:#fff; }
#title_bar .navbar-nav>li>a { padding: 2px 0;}
#title_bar .navbar-nav>li>a:hover, #title_bar .nav .open>a, .nav .open>a:focus, .nav .open>a:hover { background-color:#777; }
#title_bar .icon-size { font-size:45px; }
#profile-menu .dropdown-menu  { background-color: #777; min-width:275px;}
#profile-menu .dropdown-menu a:hover { color: #777;}

.sign-in-logo {display: block;max-width: 300px;margin-left: auto;margin-right: auto;margin-top: 100px;margin-bottom: 20px;}
#editor { min-height:400px;}


.jobs .panel{  border:1px solid #ddd; }
.page-profile .panel-title{font-size:16px;}

#map-canvas-0 {min-width:300px; min-height:600px;}
#SearchBy {width:100%;padding-top:5px;}

.jobs {float:left;min-width:340px;background-color:#eee;padding-top:20px;}
.jobs .panel-body .fa {font-size:30px;margin-top:-16px;text-align:center; color:#f75448;display:block; width:30px;}
.jobs .panel-body .disabled {color:#777; cursor:not-allowed;}
.panel-heading .row {padding:0 15px;}
#list-view {padding:0;}
#list-view .panel-body {padding:5px;}
#list-view .panel {cursor:pointer; background:#fff;border-radius:2px;}
#list-view .panel:hover {border-color:#aaa; }
#map-wrapper {background-color:#eee;margin-top:40px;position:fixed;top:0;height:93%;position: fixed;right: 0;top: 0;width:52%;}
#map-holder { height:100%;width:100%;  }
.select_users {min-width: 300px; width: 33%}
#action-wrapper {width:100%;margin-left:240px;padding-left:16px;}

/*box shadows for material design look*/
#list-view .panel, .btn,.overview a.button{box-shadow: 0 2px 5px 0 rgba(0,0,0,.16), 0 2px 10px 0 rgba(0,0,0,.12);}
.input-group-btn>.btn {box-shadow:none;}


.tab-content { padding-bottom: 100px;}
a.morelink { text-decoration:none;outline: none;}
.morecontent span { display: none;}

/* remove padding from main content div */
#main_content {padding:0;}
.status {padding-top:5px;}
.status > div {padding:6px;}
/* overview page customizations */
.overview {margin-top:3px; background:#fff; min-height:500px}
.overview a.button {border:2px solid #ccc;border-radius:3px;padding:8px;width:75px;font-size:12px; cursor:pointer;}
.overview a.button:hover {background:#eee; cursor:pointer;}
.overview a.button i {font-size:24px;}
.upToBar {margin-top:-64px;width:50%;}
.form-control[disabled],.form-control[readonly] {color:#8C8C8C;font-size:14px;}
.form-control-boolean > .form-control-boolean { width:auto; margin-top:11px;}
.dataTables_filter .form-control-feedback { right: 16px !important; }
div.job { background-color:#fff;}
.job .panel-default > .panel-heading{background-color:#d9edf7;padding:6px 10px;}
/* media query for jobs list */
@media only screen and (max-width: 1280px) { 
    .jobs {min-width:270px;}
    #navbar i {padding-right:10px;}
}
@media only screen and (max-width: 1080px) { 
    #navbar i {padding-right:5px;}
    #map-wrapper {width:46%;}
}
@media only screen and (max-width: 952px) { 
    #map-wrapper {width:40%;}
}
@media only screen and (max-width: 860px) { 
    #map-wrapper {width:35%;}
}
@media only screen and (max-width: 768px) {
    .page-worker .navbar {margin-bottom:0;}
    #main_content {background:#eee;}
    #map-wrapper {position:absolute;margin-left:32%;width:67%;padding:15px;}
    .jobs {min-width:265px;width:32%;}
    #nav-wrapper {width:100%;height:auto;position:relative;}
    #content-wrapper{padding-left:0;}
    #navbar {background-color:#333;}
    #action-nav {margin-left:0;}
    #left-main-nav {width:auto;overflow-y:hidden;}

}
@media only screen and (max-width: 600px) {
    .jobs {height: 300px;width:100%;overflow-y: scroll;border-bottom:2px solid #ccc;}
    #map-wrapper {position:absolute;top:300px;width:100%;margin-top:0;}
    #map-canvas-0 {min-height:0;}
    .page-jobs #main_content{position:relative;}
    .page-jobs {font-size:12px;}
    .nav_icons {padding-top:15px;}
}

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/
@media only screen and (min-width : 320px) {
        
}

/* Extra Small Devices, Phones */ 
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}
